import axios from 'axios';

function enviarDadosParaBackend(callID, filial) {
    let data = JSON.stringify({
      "filial": filial,
      "Callid": callID
    });
    
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `https://apioficial.krolik.com.br/calls/`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      alert(error.request.response);
    });
    
  }

  export default enviarDadosParaBackend;
