import "./Styles/App.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MinhaLogo from "./imagens/logo-krolik.png";
import jwt_decode from "jwt-decode";

function App() {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  async function authenticate(email, senha) {
    let data = JSON.stringify({
      email: email,
      password: senha,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://apioficial.krolik.com.br/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios
        .request(config);
      const token = response.data.token;
      const decodedToken = jwt_decode(token);
      const ramal = decodedToken.ramal;
      const role = decodedToken.role;
      localStorage.setItem("token", token);
      localStorage.setItem("ramal", ramal);
      localStorage.setItem("role", role);
      navigate("/user");
    } catch (error) {
      console.log(error);
      alert(error.response.data.mensagem);
    }
  }

  function handleEntrar(event) {
    event.preventDefault();
    authenticate(email, senha)
      .then((token) => setToken(token))
      .catch((error) => console.log(error));
  }

  return (
    <section className="container_">
      <div className="container-card_">
        <div className="card_">
          <img className="logo_" src={MinhaLogo} alt="Logo Krolik" />

          <h1 className="login_">Login</h1>
          <div className="container-input_">
            <div className="container-i_">
              <input
                className="input-email_"
                placeholder="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <input
                className="input-senha_"
                type="password"
                placeholder="Senha"
                value={senha}
                onChange={(event) => setSenha(event.target.value)}
              />
            </div>
            <div className="container-botao_">
              <button className="btn-enter_" onClick={handleEntrar}>
                Entrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default App;
