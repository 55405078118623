import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://apioficial.krolik.com.br/',
});

instance.interceptors.request.use(
  (config) => {
    const token = document.cookie
      ? document.cookie.split('; ')
          .find((row) => row.startsWith('token='))
          ?.split('=')[1]
      : null;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default instance;