import { useState, useEffect } from "react";
import io from "socket.io-client";
import "../Styles/User.css";
import LogoutIcon from "../imagens/sign-out-alt-solid.svg";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import filiaisOrdenadas from "./filiais.js";
import enviarDadosParaBackend from "../scripts/reqUpdateCall";

function User() {
  const [conectado, setConectado] = useState(false);
  const [socket, setSocket] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [filialSelecionada, setFilialSelecionada] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const ramal = localStorage.getItem("ramal");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!socket) {
      connectSocket(ramal, setConectado, setSocket);
      setIsAdmin(localStorage.getItem("role") === "admin");
    }

    setFilialSelecionada(filiaisOrdenadas[0].nome);

    return () => {
      try {
        socket && socket.disconnect();
        setConectado(false);
        console.log(`Desconectado do servidor pelo ramal ${ramal}`);
      } catch (error) {
        console.log(`Erro ao desconectar do servidor: ${error}`);
      }
    };
  }, [ramal, socket, setConectado, setSocket]);

  const formatarData = (data) => {
    const dataFormatada = format(
      new Date(data),
      "dd 'de' MMMM 'de' yyyy 'às' HH:mm:ss",
      { locale: ptBR }
    );
    return dataFormatada;
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleConnect = (socket, ramal, setConectado) => {
    console.log(`Conectado com sucesso no ramal ${ramal}`);
    socket.emit("conectar", ramal);
    setConectado(true);
  };

  const handleDisconnect = (socket, ramal, setConectado) => {
    setConectado(false);
    socket.removeAllListeners();
    setTimeout(() => {
      socket.connect(); // Tentar se reconectar após um intervalo de tempo
    }, 5000);
  };

  const connectSocket = (ramal, setConectado, setSocket) => {
    if (!ramal || !token || typeof ramal !== "string" || ramal.trim() === "") {
      alert("Faça o login novamente.");
      navigate("/");
      return;
    }

    try {
      const newSocket = io("https://direcionamentoporta.krolik.com.br/", {
        reconnectionAttempts: 10, // Tentar se reconectar até 10 vezes em caso de falha
        timeout: 5000, // Definir um timeout de 5 segundos para a conexão
      });
      newSocket.on("connect", () => {
        console.log(`Conectado ao servidor com sucesso!`);
        handleConnect(newSocket, ramal, setConectado);
      });
      newSocket.on(`mensagem-${ramal}`, (data) => {
        console.log("conectou no ramal correto ", ramal);
        setModalData({ ...data, ramal }); // adicionar o ramal aos dados recebidos
        alert("Ligação finalizada, por favor informar sua filial.");
        setShowModal(true);
      });
      newSocket.on("disconnect", () =>
        handleDisconnect(newSocket, ramal, setConectado)
      );

      setSocket(newSocket);
    } catch (error) {
      console.log(`Erro ao conectar com o servidor: ${error}`);
      setConectado(false);
      setSocket(null);
    }
  };

  const handleToggle = () => {
    if (conectado) {
      handleDisconnect(socket, ramal, setConectado);
    } else {
      connectSocket(ramal, setConectado, setSocket);
    }
  };

  function handleClick() {
    const id = modalData[6];
    enviarDadosParaBackend(id, filialSelecionada);
    alert("Filial informada, obrigado.");
    setShowModal(false);
  }

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  function handleFilialSelecionada(event) {
    const filialSelecionada = event.target.value;
    setFilialSelecionada(filialSelecionada);
  }

  return (
    <>
      {showModal && (
        <div className="modalContent">
          <div className="modal">
            <div className="modalRow">
              <div className="modalField">
                <div className="modalFieldTitle">Data</div>
                <div className="modalFieldValue">
                  {formatarData(modalData[0])}
                </div>
              </div>
              <div className="modalField">
                <div className="modalFieldTitle">Número de Origem</div>
                <div className="modalFieldValue">{modalData[1]}</div>
              </div>
              <div className="modalField">
                <div className="modalFieldTitle">Número de Destino</div>
                <div className="modalFieldValue">{modalData[2]}</div>
              </div>
              <div className="modalField">
                <div className="modalFieldTitle">Duração da conversa</div>
                <div className="modalFieldValue">{modalData[4]}s</div>
              </div>
              <div className="modalField">
                <div className="modalFieldTitle">Status da chamada</div>
                <div className="modalFieldValue">{modalData[5]}</div>
              </div>
              <div className="modalField">
                <div className="modalFieldTitle">ID da requisição</div>
                <div className="modalFieldValue">{modalData[7]}</div>
              </div>
            </div>
            <div className="modalRow">
              <div className="modalField filial">
                <div className="modalFieldTitle">Filial</div>
                <select
                  className="list_filiais"
                  onChange={handleFilialSelecionada}
                >
                  {filiaisOrdenadas.map((filiaisOrdenadas) => (
                    <option
                      key={filiaisOrdenadas.id}
                      value={filiaisOrdenadas.nome}
                    >
                      {filiaisOrdenadas.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modalActions">
              <button className="btnConfirm" onClick={handleClick}>
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="toolbar">
        <div className="toolbar-item">
          <span>Status: </span>
          <div className="switch">
            <button
              className={`button ${conectado ? "button-on" : "button-off"}`}
              onClick={() => handleToggle()}
            >
              {conectado ? "On" : "Off"}
            </button>
          </div>
        </div>
        <div className="toolbar-item">
          {isAdmin && (
            <div className="toolbar-item">
              <button
                className="button-dashboard"
                onClick={() => handleDashboardClick()}
              >
                Dashboard
              </button>
            </div>
          )}
          {!isAdmin && <span>Ramal: {ramal}</span>}
        </div>
        <div className="toolbar-item">
          <button className="button-logout" onClick={() => handleLogout()}>
            <img src={LogoutIcon} alt="Logout" />
          </button>
        </div>
      </div>
    </>
  );
}

export default User;
