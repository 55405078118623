import React, { useState, useEffect } from "react";
import "./Card.css";
import instance from "../../scripts/axiosinstace";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, {
  utils,
} from "@hassanmojab/react-modern-calendar-datepicker";
import myCustomLocale from "../CalendarData.js";

const Card = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setIsAdmin(localStorage.getItem("role") === "admin");
    updateData();
  }, []);

  async function updateData(startDate, endDate) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    };

    instance
      .get("dashboard/countCalls?", config)
      .then((response) => {
        const newData = response.data.map((item) => {
          return {
            filial: item.filial,
            chamadas: item.chamadas,
            mediaDuracao: item.mediaDuracao,
          };
        });
        setData(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleFilter() {
    await updateData(
      moment(
        new Date(
          selectedDayRange.from.year,
          selectedDayRange.from.month - 1,
          selectedDayRange.from.day
        )
      ).toISOString(),
      moment(
        new Date(
          selectedDayRange.to.year,
          selectedDayRange.to.month - 1,
          selectedDayRange.to.day
        )
      ).toISOString()
    );
  }

  function compare(a, b) {
    // ordena por chamadas em ordem decrescente
    if (b.chamadas !== a.chamadas) {
      return b.chamadas - a.chamadas;
    }

    // se as chamadas são iguais, ordena por mediaDuracao em ordem decrescente
    return b.mediaDuracao - a.mediaDuracao;
  }

  const filteredData = data.filter((item) =>
    item.filial.toLowerCase().includes(searchText.toLowerCase())
  );

  function handleMouseOver(index) {
    setHoveredCardIndex(index);
  }

  function handleMouseLeave() {
    setHoveredCardIndex(null);
  }

  return (
    <>
      {isAdmin ? (
        <>
          <div className="date-inputs-container">
            <div className="date-inputs">
              <label htmlFor="date-range">Período:</label>
              <DatePicker
                value={selectedDayRange}
                onChange={setSelectedDayRange}
                inputPlaceholder="Selecione um período"
                shouldHighlightWeekends={true}
                inputClassName="my-custom-input"
                colorPrimary="#7235AC"
                colorPrimaryLight="#bfa9c5"
                calendarClassName="calendarioClass"
                locale={myCustomLocale}
                maximumDate={utils().getToday()}
                renderFooter={() => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "1rem 2rem",
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedDayRange({ from: null, to: null });
                        updateData();
                      }}
                      style={{
                        border: "#0fbcf9",
                        color: "#fff",
                        borderRadius: "0.5rem",
                        padding: "1rem 2rem",
                      }}
                    >
                      Apagar escolhas
                    </button>
                  </div>
                )}
              />
              <button onClick={handleFilter}>Filtrar</button>
            </div>
          </div>
          <div className="search-filial">
            <input
              type="text"
              placeholder="Procure uma filial"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <div className="container_card">
            {filteredData.sort(compare).map((item, index) => (
              <div
                className={`card_${
                  hoveredCardIndex === index ? "hovered" : ""
                }`}
                key={index}
                onMouseOver={() => handleMouseOver(index)}
                onMouseLeave={() => handleMouseLeave()}
              >
                <h2>{item.filial || "Não declaradas"}</h2>
                <p>{`Ligações recebidas: ${item.chamadas}`}</p>
                {hoveredCardIndex === index && (
                  <p
                    className="media-duracao"
                  >{`Média de duração: ${Math.round(item.mediaDuracao)}s`}</p>
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <p className="not-authorized-message">
            Você não tem autorização para acessar esta página.
          </p>
        </>
      )}
    </>
  );
};

export default Card;
