const filiais = [
    {id: 1, nome: 'Araxá - MG'},
    {id: 2, nome: 'Campinas - SP'},
    {id: 3, nome: 'Pirassununga - SP'},
    {id: 4, nome: 'São Paulo (Tatuapé) - SP'},
    {id: 5, nome: 'Sertãozinho - SP'},
    {id: 6, nome: 'Araraquara - SP'},
    {id: 7, nome: 'Barretos - SP'},
    {id: 8, nome: 'Batatais - SP'},
    {id: 9, nome: 'Cajamar - SP'},
    {id: 10, nome: 'Catanduva - SP'},
    {id: 11, nome: 'Fernandópolis - SP'},
    {id: 12, nome: 'Foz do Iguaçu - PR'},
    {id: 13, nome: 'Franca - SP'},
    {id: 14, nome: 'Guaíra - SP'},
    {id: 15, nome: 'Ibitinga - SP'},
    {id: 16, nome: 'Itapema – SC'},
    {id: 17, nome: 'Itaquaquecetuba - SP'},
    {id: 18, nome: 'Jaboticabal - SP'},
    {id: 19, nome: 'Jardinópolis - SP'},
    {id: 20, nome: 'Limeira - SP'},
    {id: 21, nome: 'Palmas - TO'},
    {id: 22, nome: 'Pitangueiras - SP'},
    {id: 23, nome: 'Porto Ferreira - SP'},
    {id: 24, nome: 'Ribeirão Preto - SP'},
    {id: 25, nome: 'São Carlos - SP'},
    {id: 26, nome: 'São José do Rio Preto - SP'},
    {id: 27, nome: 'São José dos Campos - SP'},
    {id: 28, nome: 'São Simão - SP'},
    {id: 29, nome: 'Sorocaba - SP'},
    {id: 30, nome: 'Três Lagoas - MS'},
    {id: 31, nome: 'Uberaba - MG'},
    {id: 32, nome: 'Votuporanga - SP'},
    {id: 33, nome: 'Bebedouro - SP'},
    {id: 34, nome: 'Avaré - SP'},
    {id: 35, nome: 'Botucatu - SP'}
  ];

  const filiaisOrdenadas = filiais.sort((a, b) => a.nome.localeCompare(b.nome));

  export default filiaisOrdenadas;