import React from 'react';
import ReactDOM from "react-dom";
import './Styles/index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route, RouterProvider
} from 'react-router-dom';
import 'typeface-roboto';
// import Dashboard from './routes/Dashboard';
import User from './routes/User';
import Dashboard from './routes/Dashboard';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/User" element={<User />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);