import { useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Card from "../components/Card/Card";
import React from "react";
import qs from "qs";
import instance from "../scripts/axiosinstace";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const ramal = localStorage.getItem("ramal");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!ramal || !token || typeof ramal !== "string" || ramal.trim() === "") {
      alert("Faça o login novamente.");
      navigate("/");
    }
  }, []);

  return (
    <>
      <div>
        <button
          className="btn-go-to-user"
          onClick={() => {
            navigate("/user");
          }}
        >
          ←
        </button>
        <Card />
      </div>
    </>
  );
}

export default Dashboard;
